import { useState, useRef, useEffect } from 'react';
import {
  ErrorBoundary as ReactErrorBoundary,
  ErrorBoundaryProps as ReactErrorBoundaryProps,
  FallbackProps,
} from 'react-error-boundary';
import { ArrowRightIcon } from 'nextra/icons';
import { clsx } from 'clsx';
import { useRouter } from 'next/router';
import { Collapse } from './Collapse';
import { logError } from '@/utils';
import { Alert } from '@/components';

export type ErrorBoundaryProps = Pick<
  ReactErrorBoundaryProps,
  'children' | 'FallbackComponent'
>;

/**
 * A React ErrorBoundary for catching and logging errors. Can specify a `FallbackComponent` to render when an error is
 * caught, otherwise a default component showing an error Alert will be used.
 */
export function ErrorBoundary({
  children,
  FallbackComponent = ErrorAlertFallback,
}: ErrorBoundaryProps) {
  const handleError: ReactErrorBoundaryProps['onError'] = (error, info) => {
    logError(error, info);
  };

  return (
    <ReactErrorBoundary
      FallbackComponent={FallbackComponent}
      onError={handleError}
    >
      {children}
    </ReactErrorBoundary>
  );
}

/**
 * A default FallbackComponent for the ErrorBoundary that shows an error Alert with a generic error message and details
 * which can be expanded.
 */
function ErrorAlertFallback({ error, resetErrorBoundary }: FallbackProps) {
  // See if we can pull out an error message to show in the details
  let errorMessage: string | undefined = undefined;
  if (typeof error === 'string') {
    errorMessage = error;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  }

  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  // By default, reset the error boundary if a user navigates away from the page
  // that originally showed the error
  const router = useRouter();
  const originalPath = useRef(router.asPath);
  useEffect(() => {
    if (router.asPath !== originalPath.current) {
      resetErrorBoundary();
    }
  }, [router.asPath, resetErrorBoundary]);

  return (
    <Alert severity="error" className="mb-4 w-full">
      <section>
        <h1 className="text-base font-semibold">
          Something happened on our end
        </h1>
        <p className="mb-4 text-base">
          Try again and contact Chronosphere support if the issue persists.
        </p>

        {errorMessage && (
          <div className="-ml-1">
            <button
              className="flex items-center gap-2"
              onClick={() => setIsDetailsOpen((isOpen) => !isOpen)}
            >
              <ArrowRightIcon
                className="h-[18px] min-w-[18px] rounded-sm p-0.5 hover:bg-gray-800/5 dark:hover:bg-gray-100/5"
                pathClassName={clsx(
                  'origin-center transition-transform rtl:-rotate-180',
                  isDetailsOpen && 'ltr:rotate-90 rtl:rotate-[-270deg]'
                )}
              />
              Error details
            </button>
            <Collapse isOpen={isDetailsOpen}>
              <div className="ml-6">{errorMessage}</div>
            </Collapse>
          </div>
        )}
      </section>
    </Alert>
  );
}
