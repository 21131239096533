import { clsx } from 'clsx';

export type ButtonProps = React.ComponentProps<'button'>;

/**
 * An HTML `<button>` element, not currently used in MDX content itself but
 * used to render some of the chrome around pre/code blocks.
 */
export function Button({ children, className, ...props }: ButtonProps) {
  return (
    <button
      className={clsx(
        'transition-all active:opacity-50',
        'rounded-md border border-black/5 bg-green-700/5 p-1.5 text-gray-600 hover:text-gray-900',
        'dark:border-white/10 dark:bg-green-300/10 dark:text-gray-400 dark:hover:text-gray-50',
        'supports-[backdrop-filter]:bg-opacity-[.85] supports-[backdrop-filter]:backdrop-blur-md supports-[backdrop-filter]:dark:bg-opacity-80',
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
}
