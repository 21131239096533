import { useMemo } from 'react';
import { normalizePages } from 'nextra/normalize-pages';
import { useFSRoute } from 'nextra/hooks';
import { Footer } from './Footer';
import { Navbar } from './Navbar';
import { Sidebar } from './Sidebar';
import { TableOfContents } from './TableOfContents';
import { usePageOpts } from './PageOptsContext';
import { MainContent } from './MainContent';
import { ErrorBoundary } from './components';
import { NormalizedPagesContext } from './NormalizedPagesContext';
import { MobileMenu } from './MobileMenu';
import { DEFAULT_LOCALE } from '@/utils';

export interface LayoutProps {
  children?: React.ReactNode;
}

/**
 * Main layout component with the structure for every page on the site.
 */
export function Layout({ children }: LayoutProps) {
  const { pageMap } = usePageOpts();
  const fsPath = useFSRoute();
  const normalizedPages = useMemo(
    () =>
      normalizePages({ list: pageMap, route: fsPath, locale: DEFAULT_LOCALE }),
    [pageMap, fsPath]
  );

  return (
    <NormalizedPagesContext.Provider value={normalizedPages}>
      <div className="chrono-content">
        <Navbar />

        <div className="mx-auto flex max-w-[90rem]">
          <ErrorBoundary>
            <MobileMenu />

            <Sidebar />

            <TableOfContents />

            <MainContent>{children}</MainContent>
          </ErrorBoundary>
        </div>

        <Footer />
      </div>
    </NormalizedPagesContext.Provider>
  );
}
