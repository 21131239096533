import React from 'react';
import { SvgIcon, SvgIconProps } from './SvgIcon';

export const ServiceNavIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props} aria-label="Services">
      <path d="M0 13.5463C0 13.3708 0.157997 13.2607 0.284979 13.3478L4.18974 16.0263C4.24909 16.067 4.28571 16.1427 4.28571 16.2248V19.7709C4.28571 19.9465 4.12772 20.0566 4.00074 19.9695L0.0959735 17.291C0.0366259 17.2503 0 17.1745 0 17.0925V13.5463Z" />
      <path d="M5.2381 16.2248C5.2381 16.1427 5.27472 16.067 5.33407 16.0263L9.23883 13.3478C9.36581 13.2607 9.52381 13.3708 9.52381 13.5463V17.0925C9.52381 17.1745 9.48718 17.2503 9.42784 17.291L5.52307 19.9695C5.39609 20.0566 5.2381 19.9465 5.2381 19.7709V16.2248Z" />
      <path d="M4.85641 15.0372L8.75079 12.3658C8.87876 12.2781 8.87876 12.0566 8.75079 11.9688L4.85641 9.29746C4.79785 9.25729 4.72596 9.25729 4.6674 9.29746L0.773017 11.9688C0.645052 12.0566 0.645052 12.2781 0.773016 12.3658L4.6674 15.0372C4.72596 15.0774 4.79785 15.0774 4.85641 15.0372Z" />
      <path d="M5.23804 4.279C5.23804 4.10344 5.39603 3.99337 5.52302 4.08048L9.42778 6.75893C9.48713 6.79964 9.52375 6.8754 9.52375 6.95746V10.5036C9.52375 10.6792 9.36575 10.7892 9.23877 10.7021L5.33401 8.02368C5.27466 7.98297 5.23804 7.90721 5.23804 7.82515V4.279Z" />
      <path d="M10.4761 6.95746C10.4761 6.8754 10.5128 6.79964 10.5721 6.75893L14.4769 4.08048C14.6039 3.99337 14.7618 4.10344 14.7618 4.279V7.82515C14.7618 7.9072 14.7252 7.98297 14.6659 8.02368L10.7611 10.7021C10.6341 10.7892 10.4761 10.6792 10.4761 10.5036V6.95746Z" />
      <path d="M10.0944 5.76985L13.9888 3.09851C14.1168 3.01073 14.1168 2.78924 13.9888 2.70147L10.0944 0.0301261C10.0359 -0.010042 9.964 -0.010042 9.90544 0.0301261L6.01105 2.70147C5.88309 2.78924 5.88309 3.01073 6.01105 3.09851L9.90544 5.76985C9.964 5.81002 10.0359 5.81002 10.0944 5.76985Z" />
      <path d="M10.4761 13.5463C10.4761 13.3708 10.6341 13.2607 10.7611 13.3478L14.6658 16.0263C14.7252 16.067 14.7618 16.1427 14.7618 16.2248V19.7709C14.7618 19.9465 14.6038 20.0566 14.4768 19.9695L10.572 17.291C10.5127 17.2503 10.4761 17.1745 10.4761 17.0925V13.5463Z" />
      <path d="M15.7142 16.2248C15.7142 16.1427 15.7508 16.067 15.8101 16.0263L19.7149 13.3478C19.8419 13.2607 19.9999 13.3708 19.9999 13.5463V17.0925C19.9999 17.1745 19.9633 17.2503 19.9039 17.291L15.9991 19.9695C15.8722 20.0566 15.7142 19.9465 15.7142 19.7709V16.2248Z" />
      <path d="M15.3325 15.0372L19.2269 12.3658C19.3548 12.2781 19.3548 12.0566 19.2269 11.9688L15.3325 9.29746C15.2739 9.25729 15.202 9.25729 15.1435 9.29746L11.2491 11.9688C11.1211 12.0566 11.1211 12.2781 11.2491 12.3658L15.1435 15.0372C15.202 15.0774 15.2739 15.0774 15.3325 15.0372Z" />
    </SvgIcon>
  );
};
