import { clsx } from 'clsx';
import type { ComponentProps } from 'react';

/**
 * A `<code>` element in MDX content.
 */
export function Code({
  children,
  className,
  ...props
}: ComponentProps<'code'>) {
  const hasLineNumbers = 'data-line-numbers' in props;
  return (
    <code
      className={clsx(
        // TODO: Remove this class once switched to custom theme
        'chrono-code',
        'break-words rounded-md border border-black border-opacity-[0.04] bg-black bg-opacity-[0.03] px-[.25em] py-0.5 text-[.9em]',
        'dark:border-white/10 dark:bg-white/10',
        hasLineNumbers && '[counter-reset:line]',
        className
      )}
      // always show code blocks in ltr
      dir="ltr"
      {...props}
    >
      {children}
    </code>
  );
}
