import { useMemo } from 'react';
import { FlexSearchIndexes, SectionDocumentType } from './flexsearch-indexes';

export interface PageSearchResult {
  id: string;
  pageTitle: string;
  sectionResults: SectionSearchResult[];
}

export interface SectionSearchResult {
  id: string;
  url: string;
  sectionTitle: string;
  displayContent: string;
}

/**
 * Hook that searches the FlexSearch page/section indexes for the specified
 * searchValue and returns the search results.
 */
export function useFlexSearchResults(
  searchValue: string,
  searchIndexes?: FlexSearchIndexes
): PageSearchResult[] {
  // Memoize so we only recalculate if the search value or indexes change
  return useMemo(() => {
    if (searchValue === '' || searchIndexes === undefined) return [];

    const { pageIndex, sectionIndex } = searchIndexes;

    // Show the results for the top 5 pages
    const pageIndexResults =
      pageIndex.search<true>(searchValue, 5, {
        enrich: true,
        suggest: true,
      })[0]?.result ?? [];

    const pageResults: InternalPageSearchResult[] = [];
    for (const pageIndexResult of pageIndexResults) {
      // Start building results object for this page
      const currentPage: InternalPageSearchResult = {
        id: `${pageIndexResult.id}`,
        pageTitle: pageIndexResult.doc.title,
        sectionResults: [],
        sectionTitleMatches: 0,
      };

      // Use the section index to find the top results for this page
      const sectionIndexResults =
        sectionIndex.search<true>(searchValue, 5, {
          enrich: true,
          suggest: true,
          // Search just the current page
          tag: `${pageIndexResult.id}`,
        })[0]?.result ?? [];

      // It's possible for the section index to return documents that would
      // basically be duplicates when displayed, so use this to keep track of
      // what we've already added and de-dupe those
      const seen = new Set<string>();

      for (const sectionIndexResult of sectionIndexResults) {
        const { doc } = sectionIndexResult;
        // Keep track of the number of times we matched a section title
        if (doc.type === SectionDocumentType.TITLE) {
          currentPage.sectionTitleMatches = currentPage.sectionTitleMatches + 1;
        }

        const { url, sectionTitle, displayContent } = doc;

        // De-dupe if necessary
        const seenKey = `${url}@${displayContent}`;
        if (seen.has(seenKey)) {
          continue;
        }
        seen.add(seenKey);

        currentPage.sectionResults.push({
          id: `${sectionIndexResult.id}`,
          url,
          sectionTitle,
          displayContent,
        });
      }

      // Add current page to list of results
      pageResults.push(currentPage);
    }

    // Sort pages based on the number of section title matches they had
    return pageResults.sort((a, b) => {
      return b.sectionTitleMatches - a.sectionTitleMatches;
    });
  }, [searchIndexes, searchValue]);
}

// Additional data we keep track of internally when building search results
interface InternalPageSearchResult extends PageSearchResult {
  sectionTitleMatches: number;
}
