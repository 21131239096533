import { clsx } from 'clsx';
import { forwardRef, Fragment, useState } from 'react';
import { Transition } from '@headlessui/react';

export interface SearchInputProps
  extends Omit<React.ComponentPropsWithoutRef<'input'>, 'onChange'> {
  value: string;
  onChange: (next: string) => void;
  keyboardShortcut?: React.ReactNode;
}

/**
 * Input box for searching documentation.
 */
export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  function SearchInput(
    { keyboardShortcut, className, value, onChange, onFocus, onBlur, ...props },
    ref
  ) {
    const [isFocused, setIsFocused] = useState(false);

    return (
      <div className="relative flex items-center text-gray-900 contrast-more:text-gray-800 dark:text-gray-300 contrast-more:dark:text-gray-300">
        <input
          ref={ref}
          spellCheck={false}
          className={clsx(
            className,
            'block w-full appearance-none rounded-lg px-3 py-2 transition-colors',
            'text-base leading-tight md:text-sm',
            'bg-black/[.05] dark:bg-gray-50/10',
            'focus:bg-white dark:focus:bg-dark',
            'placeholder:text-gray-500 dark:placeholder:text-gray-400',
            'contrast-more:border contrast-more:border-current'
          )}
          type="search"
          placeholder="Search documentation…"
          value={value}
          onChange={(e) => {
            const { value } = e.target;
            onChange(value);
          }}
          onFocus={(e) => {
            setIsFocused(true);
            onFocus?.(e);
          }}
          onBlur={(e) => {
            setIsFocused(false);
            onBlur?.(e);
          }}
          {...props}
        />

        <Transition
          show={keyboardShortcut !== undefined}
          as={Fragment}
          enter="transition-opacity"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <kbd
            className={clsx(
              'absolute my-1.5 select-none ltr:right-1.5 rtl:left-1.5',
              'h-5 rounded bg-white px-1.5 font-mono text-[10px] font-medium text-gray-500',
              'border dark:border-gray-100/20 dark:bg-dark/50',
              'contrast-more:border-current contrast-more:text-current contrast-more:dark:border-current',
              'items-center gap-1 transition-opacity',
              value
                ? 'z-20 flex cursor-pointer hover:opacity-70'
                : 'pointer-events-none hidden sm:flex'
            )}
            title={value ? 'Clear' : undefined}
            onClick={() => {
              onChange('');
            }}
          >
            {value !== '' && isFocused ? 'ESC' : keyboardShortcut}
          </kbd>
        </Transition>
      </div>
    );
  }
);
