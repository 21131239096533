import { clsx } from 'clsx';

export interface SeparatorProps {
  title?: string;
}

/**
 * A separator between menu items in the sidebar, optionally with a title.
 */
export function Separator({ title }: SeparatorProps) {
  return (
    <li
      className={clsx(
        '[word-break:break-word]',
        title
          ? 'mb-2 mt-5 px-2 py-1.5 text-sm font-semibold text-gray-900 first:mt-0 dark:text-gray-100'
          : 'my-4'
      )}
    >
      {title !== undefined ? (
        title
      ) : (
        <hr className="mx-2 border-t border-gray-200 dark:border-green-100/10" />
      )}
    </li>
  );
}
