import { Item, MenuItem, PageItem } from 'nextra/normalize-pages';
import { useContext, createContext, useState } from 'react';
import { clsx } from 'clsx';
import NextLink from 'next/link';
import { ArrowRightIcon } from 'nextra/icons';
import { useFSRoute } from 'nextra/hooks';
import { Collapse } from '../Collapse';
import { Menu } from './Menu';
import { MenuClasses } from './menu-classes';
import { useFocusedItem } from './FocusedItemContext';
import { useMobileMenu } from '@/nextra-theme/MobileMenuContext';

export interface FolderProps {
  item: PageItem | MenuItem | Item;
}

/**
 * A folder in the sidebar menu, optionally with an index page of its own that
 * can be navigated to.
 */
export function Folder({ item }: FolderProps) {
  const mobileMenu = useMobileMenu();

  // Each folder that renders increments the nesting level by one
  const folderLevel = useContext(FolderLevelContext) + 1;

  const [route] = useFSRoute().split('#');
  const isActive = [route, route + '/'].includes(item.route + '/');

  // Is the currently active route inside this folder?
  const isActiveRouteInside = isActive || route.startsWith(item.route + '/');

  // Keep track of whether the folder is open
  const [isOpen, setIsOpen] = useIsFolderOpen(
    folderLevel,
    item,
    isActiveRouteInside
  );

  const isLink = 'withIndexPage' in item && item.withIndexPage;

  // Link props common to both anchor and button elements
  const commonLinkProps = {
    className: clsx(
      'items-center justify-between gap-2',
      !isLink && 'w-full text-left',
      MenuClasses.LINK,
      isActive ? MenuClasses.ACTIVE : MenuClasses.INACTIVE
    ),
    onClick: (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
      const clickedTag = (e.target as HTMLElement).tagName.toLowerCase();
      const clickedToggleIcon = clickedTag === 'svg' || clickedTag === 'path';

      // If they clicked just the icon, prevent default to avoid also navigating
      // (which it will do if this is a link)
      if (clickedToggleIcon) {
        e.preventDefault();
      }

      if (isLink) {
        // If it's focused, we toggle it. Otherwise, always open it.
        if (isActive || clickedToggleIcon) {
          setIsOpen((current) => !current);
        } else {
          setIsOpen(true);
          mobileMenu.setIsOpen(false);
        }
        return;
      }

      // Buttons always toggle their state
      setIsOpen((current) => !current);
    },
  };

  return (
    <FolderLevelContext.Provider value={folderLevel}>
      <li className={clsx({ open: isOpen, active: isActive })}>
        {isLink ? (
          <NextLink {...commonLinkProps} href={item.route}>
            <FolderTitle item={item} isOpen={isOpen} />
          </NextLink>
        ) : (
          <button {...commonLinkProps}>
            <FolderTitle item={item} isOpen={isOpen} />
          </button>
        )}

        <Collapse className="pt-1 ltr:pr-0 rtl:pl-0" isOpen={isOpen}>
          {Array.isArray(item.children) ? (
            <Menu
              className={clsx(MenuClasses.BORDER, 'ltr:ml-3 rtl:mr-3')}
              directories={item.children}
            />
          ) : null}
        </Collapse>
      </li>
    </FolderLevelContext.Provider>
  );
}

// Context that keeps track of what level in the tree a folder is in
const FolderLevelContext = createContext(0);

const DEFAULT_MENU_COLLAPSE_LEVEL = 1;

/**
 * Helper hook that keeps track of the open state for the folder, syncing it
 * with external state like the active/focused route when the user navigates.
 */
function useIsFolderOpen(
  folderLevel: number,
  item: FolderProps['item'],
  isActiveRouteInside: boolean
) {
  // Keep track of whether the folder is open
  const [isOpen, setIsOpen] = useState<boolean>(() => {
    // If the active route is inside the folder, start open
    if (isActiveRouteInside) {
      return true;
    }

    // See if the item was configured via metadata to start as collapsed
    if ('theme' in item && item.theme?.collapsed === true) {
      return false;
    }

    // Otherwise, use the default collapse level to start collapsed for things
    // deeper in the tree than the default
    return folderLevel < DEFAULT_MENU_COLLAPSE_LEVEL;
  });

  // Keep our open state in sync with the active route as it changes (i.e. open
  // the folder if the active route is inside)
  const [prevIsActiveRouteInside, setPrevIsActiveRouteInside] =
    useState(isActiveRouteInside);

  if (isActiveRouteInside !== prevIsActiveRouteInside) {
    setPrevIsActiveRouteInside(isActiveRouteInside);
    if (isActiveRouteInside) {
      setIsOpen(true);
    }
  }

  // Is the currently focused route inside this folder? (i.e. because of
  // keyboard navigation between links)
  const { focusedItemRoute } = useFocusedItem();
  const isFocusedRouteInside =
    focusedItemRoute?.startsWith(item.route + '/') ?? false;

  // Keep our open state in sync with the focused route as it changes (i.e. open
  // the folder if the focused route is inside)
  const [prevIsFocusedRouteInside, setPrevIsFocusedRouteInside] =
    useState(isFocusedRouteInside);

  if (isFocusedRouteInside !== prevIsFocusedRouteInside) {
    setPrevIsFocusedRouteInside(isFocusedRouteInside);
    if (isFocusedRouteInside) {
      setIsOpen(true);
    }
  }

  return [isOpen, setIsOpen] as const;
}

// Helper component to render a folder's title with the icon for opening/closing
// the sub-menu (basically a shared "children" prop for both anchors/buttons)
function FolderTitle({
  item,
  isOpen,
}: {
  item: FolderProps['item'];
  isOpen: boolean;
}) {
  return (
    <>
      {item.title}
      <ArrowRightIcon
        className="h-[18px] min-w-[18px] rounded-sm p-0.5 hover:bg-gray-800/5 dark:hover:bg-gray-100/5"
        pathClassName={clsx(
          'origin-center transition-transform rtl:-rotate-180',
          isOpen && 'ltr:rotate-90 rtl:rotate-[-270deg]'
        )}
      />
    </>
  );
}
