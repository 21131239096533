import { forwardRef } from 'react';
import NextLink from 'next/link';

export interface AnchorProps extends React.ComponentPropsWithoutRef<'a'> {
  newWindow?: boolean;
}

/**
 * HTML Anchor (`<a>`) component that supports opening in a new window, but uses
 * `NextLink` by default. Note: this does not contain any styling by default.
 * Use the `Link` component instead for that use case.
 */
export const Anchor = forwardRef<HTMLAnchorElement, AnchorProps>(
  function Anchor({ newWindow = false, href, children, ...props }, ref) {
    if (newWindow) {
      return (
        <a ref={ref} href={href} target="_blank" rel="noreferrer" {...props}>
          {children}
          <span className="sr-only select-none"> (opens in a new tab)</span>
        </a>
      );
    }

    if (href === undefined) {
      return (
        <a ref={ref} {...props}>
          {children}
        </a>
      );
    }

    return (
      <NextLink ref={ref} href={href} {...props}>
        {children}
      </NextLink>
    );
  }
);
