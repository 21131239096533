import { clsx } from 'clsx';
import { Heading } from 'nextra';

export interface TocHeadingProps {
  heading: Heading;
  isActive?: boolean;
}

/**
 * A heading in the list of headings for the `TableOfContents`.
 */
export function TocHeading({ heading, isActive }: TocHeadingProps) {
  const { id, depth, value } = heading;
  return (
    <li className="my-2 scroll-my-6 scroll-py-6" key={id}>
      <a
        href={`#${id}`}
        className={clsx(
          {
            1: '',
            2: 'font-semibold',
            3: 'ltr:pl-4 rtl:pr-4',
            4: 'ltr:pl-8 rtl:pr-8',
            5: 'ltr:pl-12 rtl:pr-12',
            6: 'ltr:pl-16 rtl:pr-16',
          }[depth],
          'inline-block',
          isActive
            ? 'text-green-600 subpixel-antialiased contrast-more:!text-green-600'
            : 'text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300',
          'w-full break-words contrast-more:text-gray-900 contrast-more:underline contrast-more:dark:text-gray-50'
        )}
      >
        {value}
      </a>
    </li>
  );
}
