import { clsx } from 'clsx';
import { Anchor, AnchorProps } from './Anchor';

export type LinkProps = AnchorProps;

// Regex used to determine whether to open in a new window by default
const NEW_WINDOW_HREF_REGEX = /^https?:\/\//i;

/**
 * Renders an Anchor (`<a>`) with some default styling for links and supports
 * opening in a new window. Will automatically render links that start with
 * `http(s)` in a new window.
 */
export function Link({
  className,
  children,
  href,
  newWindow,
  ...props
}: LinkProps) {
  // By default, open links that start with `http(s)` in a new window
  if (href !== undefined) {
    newWindow ??= NEW_WINDOW_HREF_REGEX.test(href);
  }

  return (
    <Anchor
      {...props}
      className={clsx(
        'text-green-500 underline decoration-from-font [text-underline-position:from-font]',
        className
      )}
      href={href}
      newWindow={newWindow}
    >
      {children}
    </Anchor>
  );
}
