import clsx from 'clsx';
import { Breadcrumb } from './Breadcrumb';
import { NextPreviousNavLinks } from './NextPreviousNavLinks';
import { MdxContent } from './MdxContent';
import { useNormalizedPages } from '@/nextra-theme/NormalizedPagesContext';
import { ErrorBoundary } from '@/nextra-theme/components';

export interface MainContentProps {
  children: React.ReactNode;
}

/**
 * Renders the main content of a page, including the MDX markup that's been
 * converted to HTML.
 */
export function MainContent({ children }: MainContentProps) {
  return (
    <MainContentRoot>
      <ErrorBoundary>
        <Breadcrumb />
        <MdxContent>{children}</MdxContent>
        <NextPreviousNavLinks />
      </ErrorBoundary>
    </MainContentRoot>
  );
}

// Shared CSS classes for all layouts
const rootClassName = 'w-full break-words';

/**
 * Renders different wrapper elements at the root of the main content based on
 * the `layout` settings for the current page.
 */
function MainContentRoot({ children }: { children: React.ReactNode }) {
  const {
    activeThemeContext: { layout },
  } = useNormalizedPages();

  // "Raw" layout which is supposed to include minimal styling
  if (layout === 'raw') {
    return <div className={rootClassName}>{children}</div>;
  }

  // "Full" layout with takes up the full width/height of the container, but is
  // otherwise minimal
  if (layout === 'full') {
    return (
      <article
        className={clsx(
          rootClassName,
          'min-h-[calc(100vh-var(--chrono-navbar-height))] pl-[max(env(safe-area-inset-left),1.5rem)] pr-[max(env(safe-area-inset-right),1.5rem)]'
        )}
      >
        {children}
      </article>
    );
  }

  // Default layout that's used for everything unless overridden in the metadata
  return (
    <article
      className={clsx(
        rootClassName,
        'flex min-h-[calc(100vh-var(--chrono-navbar-height))] min-w-0 justify-center pb-8 pr-[calc(env(safe-area-inset-right)-1.5rem)] text-slate-700 dark:text-slate-200'
      )}
    >
      <main className="w-full min-w-0 max-w-6xl px-6 pt-4 md:px-12">
        {children}
      </main>
    </article>
  );
}
