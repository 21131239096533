import { Fragment } from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';
import { Transition } from '@headlessui/react';
import { clsx } from 'clsx';
import { InformationCircleIcon, SpinnerIcon } from 'nextra/icons';
import { PageSearchResult } from './flexsearch-results';
import { SearchResultsActiveState } from './search-result-active-state';
import { HighlightMatches } from './HighlightMatches';
import { Anchor } from '@/components';

export interface SearchResultsListProps {
  errorMessage?: string;
  loadingMessage?: string;
  searchValue: string;
  searchResults: PageSearchResult[];
  active?: SearchResultsActiveState;
  onSearchResultMouseMove: (pageIndex: number, sectionIndex: number) => void;
}

/**
 * Displays the list of search results, allowing for placeholders when the
 * search indexes are being loaded or there is an error.
 */
export function SearchResultsList({
  errorMessage,
  loadingMessage,
  searchValue,
  searchResults,
  active,
  onSearchResultMouseMove,
}: SearchResultsListProps) {
  return (
    <Transition
      show={active !== undefined}
      // Transition.Child is required here, otherwise popup will be still present in DOM after focus out
      as={Transition.Child}
      leave="transition-opacity duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <ul
        className={clsx(
          'chrono-scrollbar',
          // Using bg-white as background-color when the browser didn't support backdrop-filter
          'border border-gray-200 bg-white text-gray-100 dark:border-neutral-800 dark:bg-neutral-900',
          'absolute top-full z-20 mt-2 overflow-auto overscroll-contain rounded-xl py-2.5 shadow-xl',
          'max-h-[min(calc(50vh-11rem-env(safe-area-inset-bottom)),400px)]',
          'md:max-h-[min(calc(100vh-5rem-env(safe-area-inset-bottom)),400px)]',
          'inset-x-0 ltr:md:left-auto rtl:md:right-auto',
          'contrast-more:border contrast-more:border-gray-900 contrast-more:dark:border-gray-50',
          'min-h-[100px] w-screen max-w-[min(calc(100vw-2rem),calc(100%+20rem))]',
          'supports-[backdrop-filter]:bg-white/70 supports-[backdrop-filter]:backdrop-blur-lg supports-[backdrop-filter]:dark:bg-dark/80'
        )}
        style={{
          transition: 'max-height .2s ease', // don't work with tailwindcss
        }}
      >
        {errorMessage ? (
          <span className="flex select-none justify-center gap-2 p-8 text-center text-sm text-red-500">
            <InformationCircleIcon className="h-5 w-5" />
            {errorMessage}
          </span>
        ) : loadingMessage ? (
          <span className="flex select-none justify-center gap-2 p-8 text-center text-sm text-gray-400">
            <SpinnerIcon className="h-5 w-5 animate-spin" />
            {loadingMessage}
          </span>
        ) : searchResults.length === 0 ? (
          <span className="block select-none p-8 text-center text-sm text-gray-400">
            No results found.
          </span>
        ) : (
          searchResults.map((result, pageIndex) => (
            <Fragment key={result.id}>
              <div
                className={clsx(
                  'mx-2.5 mb-2 mt-6 select-none border-b border-black/10 px-2.5 pb-1.5 text-xs font-semibold uppercase text-gray-500 first:mt-0 dark:border-white/20 dark:text-gray-300',
                  'contrast-more:border-gray-600 contrast-more:text-gray-900 contrast-more:dark:border-gray-50 contrast-more:dark:text-gray-50'
                )}
              >
                {result.pageTitle}
              </div>
              {result.sectionResults.map(
                ({ id, url, sectionTitle, displayContent }, sectionIndex) => {
                  const isActive =
                    active?.pageIndex === pageIndex &&
                    active.sectionIndex === sectionIndex;

                  return (
                    <li
                      key={id}
                      // When we get a ref to the active list item, make sure that
                      // it's in view so that keyboard nav of the list scrolls the
                      // list properly
                      ref={
                        isActive === false
                          ? undefined
                          : (el) => {
                              if (el === null) return;
                              scrollIntoView(el, { block: 'nearest' });
                            }
                      }
                      className={clsx(
                        'mx-2.5 break-words rounded-md',
                        'contrast-more:border',
                        isActive
                          ? 'bg-green-100 text-green-600 contrast-more:border-green-500 dark:bg-green-850'
                          : 'text-gray-800 contrast-more:border-transparent dark:text-gray-300'
                      )}
                    >
                      <Anchor
                        className="block scroll-m-12 px-2.5 py-2"
                        href={url}
                        onMouseMove={() =>
                          onSearchResultMouseMove(pageIndex, sectionIndex)
                        }
                      >
                        <div className="text-base font-semibold leading-5 dark:text-white">
                          <HighlightMatches
                            searchValue={searchValue}
                            displayValue={sectionTitle}
                            className={
                              isActive ? 'text-green-600' : 'text-green-500'
                            }
                          />
                        </div>

                        <div className="mt-1 line-clamp-1 text-sm leading-[1.35rem] text-gray-600 dark:text-gray-400 contrast-more:dark:text-gray-50 md:line-clamp-none">
                          <HighlightMatches
                            searchValue={searchValue}
                            displayValue={displayContent}
                            className={
                              isActive ? 'text-green-600' : 'text-green-500'
                            }
                          />
                        </div>
                      </Anchor>
                    </li>
                  );
                }
              )}
            </Fragment>
          ))
        )}
      </ul>
    </Transition>
  );
}
