import { clsx } from 'clsx';
import { ArrowRightIcon } from 'nextra/icons';
import type { Item } from 'nextra/normalize-pages';
import { Anchor } from '@/components';
import { useNormalizedPages } from '@/nextra-theme/NormalizedPagesContext';

const classes = {
  link: 'flex max-w-[50%] items-center gap-1 py-4 text-base font-medium text-gray-600 transition-colors [word-break:break-word] hover:text-green-600 dark:text-gray-300 md:text-lg',
  icon: 'inline h-5 shrink-0',
};

/**
 * Shows links to the next/previous doc at the bottom of each docs page.
 */
export function NextPreviousNavLinks() {
  const { activeIndex, flatDocsDirectories, activeType } = useNormalizedPages();

  // Pages don't have next/previous links, so don't render anything (by default
  // activeType will be `doc` and so should render links)
  if (activeType === 'page') {
    return null;
  }

  let prev: Item | undefined = flatDocsDirectories[activeIndex - 1];
  let next: Item | undefined = flatDocsDirectories[activeIndex + 1];

  if (prev && !prev.isUnderCurrentDocsTree) prev = undefined;
  if (next && !next.isUnderCurrentDocsTree) next = undefined;

  if (prev === undefined && next === undefined) return null;

  return (
    <div
      className={clsx(
        'mb-8 mt-16 flex items-center border-t pt-8 dark:border-neutral-800',
        'contrast-more:border-neutral-400 dark:contrast-more:border-neutral-400',
        'print:hidden'
      )}
    >
      {prev && (
        <Anchor
          href={prev.route}
          title={prev.title}
          className={clsx(classes.link, 'ltr:pr-4 rtl:pl-4')}
        >
          <ArrowRightIcon className={clsx(classes.icon, 'ltr:rotate-180')} />
          {prev.title}
        </Anchor>
      )}
      {next && (
        <Anchor
          href={next.route}
          title={next.title}
          className={clsx(
            classes.link,
            'ltr:ml-auto ltr:pl-4 ltr:text-right rtl:mr-auto rtl:pr-4 rtl:text-left'
          )}
        >
          {next.title}
          <ArrowRightIcon className={clsx(classes.icon, 'rtl:rotate-180')} />
        </Anchor>
      )}
    </div>
  );
}
