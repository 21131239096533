import { PageOpts } from 'nextra';
import { createContext, useContext } from 'react';

/**
 * Allow access to the current `PageOpts` from nextra without needing to prop drill all the components.
 */
export const PageOptsContext = createContext<PageOpts | undefined>(undefined);

/**
 * Get the nextra `PageOpts` for the current page.
 */
export function usePageOpts() {
  const ctx = useContext(PageOptsContext);
  if (ctx === undefined) {
    throw new Error('No PageOptsContext available. Did you forget a Provider?');
  }
  return ctx;
}
