import { clsx } from 'clsx';

/**
 * CSS classes shared by multiple menu components.
 */
export const MenuClasses = {
  LINK: clsx(
    'flex rounded px-2 py-1.5 text-sm transition-colors [word-break:break-word]',
    'cursor-pointer [-webkit-tap-highlight-color:transparent] [-webkit-touch-callout:none] contrast-more:border'
  ),
  INACTIVE: clsx(
    'text-gray-500 hover:bg-gray-100 hover:text-gray-900',
    'dark:text-neutral-400 dark:hover:bg-green-100/5 dark:hover:text-gray-50',
    'contrast-more:text-gray-900 contrast-more:dark:text-gray-50',
    'contrast-more:border-transparent contrast-more:hover:border-gray-900 contrast-more:dark:hover:border-gray-50'
  ),
  ACTIVE: clsx(
    'bg-green-100 font-semibold text-green-800 dark:bg-green-850 dark:text-white',
    'contrast-more:border-green-500 contrast-more:dark:border-green-500'
  ),
  LIST: clsx('flex flex-col gap-1'),
  BORDER: clsx(
    'relative before:absolute before:inset-y-1',
    'before:w-px before:bg-gray-200 before:content-[""] dark:before:bg-neutral-800',
    'ltr:pl-3 ltr:before:left-0 rtl:pr-3 rtl:before:right-0'
  ),
};
