import { useState } from 'react';
import { useRouter } from 'next/router';
import { PageSearchResult } from './flexsearch-results';

export interface SearchResultsActiveState {
  pageIndex: number;
  sectionIndex: number;
}

/**
 * Manages which search result is "active" (i.e. highlighted) in the list of
 * results and provides some helper functions for manipulating that state.
 */
export function useSearchResultsActiveState(searchResults: PageSearchResult[]) {
  const [active, setActive] = useState<SearchResultsActiveState | undefined>(
    undefined
  );

  // Set the first search result active
  const setFirstResultActive = () => {
    setActive({ pageIndex: 0, sectionIndex: 0 });
  };

  // Set the next result in the overall list active, taking into account pages
  const setNextResultActive = () => {
    setActive((current) => {
      if (current === undefined) {
        return;
      }

      const { pageIndex, sectionIndex } = current;

      // Go to next section on same page?
      const nextSectionIndex = sectionIndex + 1;
      if (nextSectionIndex < searchResults[pageIndex].sectionResults.length) {
        return { pageIndex, sectionIndex: nextSectionIndex };
      }

      // Go to first section on next page?
      const nextPageIndex = pageIndex + 1;
      if (nextPageIndex < searchResults.length) {
        return { pageIndex: nextPageIndex, sectionIndex: 0 };
      }

      // There is no next page so this is a no-op
      return current;
    });
  };

  // Set the prev result in the overall list active, taking into account pages
  const setPreviousResultActive = () => {
    setActive((current) => {
      if (current === undefined) {
        return;
      }

      const { pageIndex, sectionIndex } = current;

      // Go to previous section on same page?
      const prevSectionIndex = sectionIndex - 1;
      if (prevSectionIndex >= 0) {
        return { pageIndex, sectionIndex: prevSectionIndex };
      }

      // Go to last section on previous page?
      const prevPageIndex = pageIndex - 1;
      if (prevPageIndex >= 0) {
        const lastSectionIndex =
          searchResults[prevPageIndex].sectionResults.length - 1;
        return { pageIndex: prevPageIndex, sectionIndex: lastSectionIndex };
      }

      // There is no previous page so this is a no-op
      return current;
    });
  };

  // Navigate to the currently active result
  const router = useRouter();
  const navigateToActiveResult = () => {
    if (active === undefined) {
      return;
    }

    const { pageIndex, sectionIndex } = active;
    const route = searchResults[pageIndex]?.sectionResults[sectionIndex]?.url;
    if (route !== undefined) {
      router.push(route);
    }
  };

  return {
    active,
    setActive,
    setFirstResultActive,
    setNextResultActive,
    setPreviousResultActive,
    navigateToActiveResult,
  };
}
