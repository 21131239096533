import type { NextraThemeLayoutProps } from 'nextra';
import { ThemeProvider } from 'next-themes';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { PageOptsContext } from './PageOptsContext';
import { MobileMenuProvider } from './MobileMenuContext';
import { Head } from './Head';
import { Layout } from './Layout';
import { ErrorBoundary } from './components';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

/**
 * The main, top-level component rendered by next/nextra for every page on the site.
 */
export default function ThemeLayout({
  children,
  pageOpts,
}: NextraThemeLayoutProps) {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider attribute="class" disableTransitionOnChange>
          <PageOptsContext.Provider value={pageOpts}>
            <MobileMenuProvider>
              <Head />
              <Layout>{children}</Layout>
            </MobileMenuProvider>
          </PageOptsContext.Provider>
        </ThemeProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}
