import { clsx } from 'clsx';
import { ArrowRightIcon } from 'nextra/icons';
import { Fragment } from 'react';
import { Anchor } from '@/components';
import { useNormalizedPages } from '@/nextra-theme/NormalizedPagesContext';

const getSectionName = (
  docsDirectories: { title: string; name: string; type: string }[],
  activePath: { title: string; name: string }[]
) => {
  try {
    // There's not a true hierarchy; the separators are siblings of the directories, not parents,
    // so we need to figure out where the current directory lies in relation to the nearest separator:
    const separators: { name: string; idx: number }[] = [];

    docsDirectories.forEach((dir, idx) => {
      if (dir.type === 'separator') {
        separators.push({
          name: dir.title,
          idx,
        });
      }
    });

    // `activePath` contains an array of [section, section child, etc.], so the first in the
    // array is the section we need:
    const section = activePath[0];

    const currentSectionIdx = docsDirectories.findIndex(
      (page) => page.name === section.name && page.title === section.title
    );

    const parentSeparator = separators.findLast(
      (separators) => separators.idx < currentSectionIdx
    );

    return parentSeparator?.name;
  } catch {
    // Means there's no root section for some reason-- omit text and ArrowRightIcon.
  }
};

/**
 * Displays breadcrumbs at the top of docs pages.
 */
export function Breadcrumb() {
  const { activePath, activeType, docsDirectories } = useNormalizedPages();
  const sectionName = getSectionName(docsDirectories, activePath);

  // Pages don't have breadcrumbs, so don't render anything (by default,
  // activeType should be `doc` and so should render breadcrumbs)
  if (activeType === 'page') {
    return null;
  }

  return (
    <div className="mt-1.5 flex items-center gap-1 overflow-hidden text-sm uppercase text-gray-500 contrast-more:text-current dark:text-gray-400">
      {sectionName !== undefined && (
        <>
          <div
            className={clsx('whitespace-nowrap transition-colors', [
              'min-w-[24px] overflow-hidden text-ellipsis',
            ])}
            title={sectionName}
          >
            {sectionName}
          </div>
          <ArrowRightIcon className="w-3.5 shrink-0" />
        </>
      )}
      {activePath.map((item, index) => {
        const isLink = item.children === undefined || item.withIndexPage;
        const isActive = index === activePath.length - 1;

        return (
          <Fragment key={item.route + item.name}>
            {index > 0 && <ArrowRightIcon className="w-3.5 shrink-0" />}
            <div
              className={clsx(
                'whitespace-nowrap transition-colors',
                isActive
                  ? 'font-medium text-gray-700 contrast-more:font-bold contrast-more:text-current dark:text-gray-100 contrast-more:dark:text-current'
                  : [
                      'min-w-[24px] overflow-hidden text-ellipsis',
                      isLink && 'hover:text-gray-900 dark:hover:text-gray-100',
                    ]
              )}
              title={item.title}
            >
              {isLink && !isActive ? (
                <Anchor href={item.route}>{item.title}</Anchor>
              ) : (
                item.title
              )}
            </div>
          </Fragment>
        );
      })}
    </div>
  );
}
