/**
 * Renders additional content for the `<head>` element on each page.
 */
export function HeadContent() {
  return (
    <>
      <link rel="icon" href="/favicon.ico" />
    </>
  );
}
