import { createContext, useContext, useState, useMemo } from 'react';

export interface FocusedItem {
  focusedItemRoute: string | undefined;
  onItemFocus: (itemRoute: string) => void;
  onItemBlur: () => void;
}

const FocusedItemContext = createContext<FocusedItem | undefined>(undefined);

/**
 * Provider that keeps track of the currently focused item in the sidebar and
 * provides some event handlers for changing that state.
 */
export function FocusedItemProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [focusedItemRoute, setFocusedItemRoute] = useState<string | undefined>(
    undefined
  );

  const ctxValue: FocusedItem = useMemo(
    () => ({
      focusedItemRoute,
      onItemFocus: (next) => setFocusedItemRoute(next),
      onItemBlur: () => setFocusedItemRoute(undefined),
    }),
    [focusedItemRoute, setFocusedItemRoute]
  );

  return (
    <FocusedItemContext.Provider value={ctxValue}>
      {children}
    </FocusedItemContext.Provider>
  );
}

/**
 * Use the currently focused sidebar item state.
 */
export function useFocusedItem() {
  const ctx = useContext(FocusedItemContext);
  if (ctx === undefined) {
    throw new Error('No FocusedItemContext found. Did you forget a Provider?');
  }
  return ctx;
}
