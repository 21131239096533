import { Icon as MdiIcon } from '@mdi/react';

const defaultStyles: IconProps['style'] = {
  display: 'inline-block',
};

export type IconProps = Parameters<typeof MdiIcon>[0];

/**
 * A wrapper around the `Icon` component from `@mdi/react` with some default prop values set.
 */
export function Icon(props: IconProps) {
  return (
    <MdiIcon size={1} {...props} style={{ ...defaultStyles, ...props.style }} />
  );
}
