import { clsx } from 'clsx';
export interface SvgIconProps extends React.ComponentPropsWithoutRef<'svg'> {}

/**
 * Component used as a base for custom SVG Icons, similar to MUI's SvgIcon that
 * we migrated from.
 */
export function SvgIcon({ className, ...props }: SvgIconProps) {
  return (
    <svg
      className={clsx('inline-block h-6 w-6 fill-current', className)}
      {...props}
    />
  );
}
