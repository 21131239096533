export interface NotFoundPageProps {
  children?: React.ReactNode;
}

/**
 * Component rendered on the custom 404 page for the site when a page is not
 * found.
 */
export function NotFoundPage({ children }: NotFoundPageProps) {
  return (
    <div className="md:-mt-2 md:flex md:h-full md:flex-col md:items-start md:justify-center">
      <h1 className="font-primary-header text-4xl font-semibold tracking-wider">
        <span className="bg-gradient-to-r from-green-400 to-purple-700 bg-clip-text text-transparent">
          404
        </span>
      </h1>

      <p className="mt-4 text-xs font-medium uppercase">Page Not Found</p>

      <div className="mt-4">{children}</div>

      <a
        href="/"
        className="mt-4 inline-block rounded bg-green-600 px-4 py-2 font-semibold text-white transition-colors ease-in-out hover:bg-green-700"
      >
        Back to home
      </a>
    </div>
  );
}
