import { useEffect, useState } from 'react';
import { CheckIcon, CopyIcon } from 'nextra/icons';
import copy from 'clipboard-copy';
import { Button, ButtonProps } from './Button';

export type CopyToClipboardProps = ButtonProps & {
  getValueToCopy: () => string;
};

/**
 * A button for copying content to the clipboard. Calls `getValueToCopy` to
 * get the value to copy to the clipboard.
 */
export function CopyToClipboard({
  getValueToCopy,
  ...props
}: CopyToClipboardProps) {
  const [isCopied, setCopied] = useState(false);

  // Toggles the icon back a couple seconds after a copy
  useEffect(() => {
    if (!isCopied) return;

    const timerId = setTimeout(() => {
      setCopied(false);
    }, 2000);

    return () => {
      clearTimeout(timerId);
    };
  }, [isCopied]);

  const handleClick = async () => {
    setCopied(true);
    try {
      await copy(getValueToCopy());
    } catch {
      console.error('Failed to copy!');
    }
  };

  const IconToUse = isCopied ? CheckIcon : CopyIcon;

  return (
    <Button onClick={handleClick} title="Copy code" tabIndex={0} {...props}>
      <IconToUse className="pointer-events-none h-4 w-4 animate-fade-in" />
    </Button>
  );
}
