import { normalizePages } from 'nextra/normalize-pages';
import { createContext, useContext } from 'react';

export type NormalizedPages = ReturnType<typeof normalizePages>;

/**
 * Allows access to the normalized pages data from Nextra without needing to prop drill.
 */
export const NormalizedPagesContext = createContext<
  NormalizedPages | undefined
>(undefined);

/**
 * Gets the Nextra normalized pages data for the current page.
 */
export function useNormalizedPages(): NormalizedPages {
  const ctx = useContext(NormalizedPagesContext);
  if (ctx === undefined) {
    throw new Error(
      'No NormalizedPages context found, did you forget a Provider?'
    );
  }
  return ctx;
}
