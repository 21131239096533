import { Menu, MenuProps } from './Menu';
import { FocusedItemProvider } from './FocusedItemContext';

export type DirectoriesMenuProps = MenuProps;

/**
 * Renders a navigation menu of "directories" which are basically docs
 * folders/files. Used, for example, by the main Sidebar and the MobileMenu.
 */
export function DirectoriesMenu(props: DirectoriesMenuProps) {
  return (
    <FocusedItemProvider>
      <Menu {...props} />
    </FocusedItemProvider>
  );
}
