import React from 'react';
import { SvgIcon, SvgIconProps } from './SvgIcon';

export const ServiceIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 22 20" {...props} aria-label="ServiceIcon">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7339 0.0766817C10.8966 -0.0255606 11.1034 -0.0255606 11.2661 0.0766817L16.2661 3.21954C16.4117 3.31104 16.5 3.47092 16.5 3.64286V8.35714C16.5 8.52908 16.4117 8.68896 16.2661 8.78046L11.2661 11.9233C11.1034 12.0256 10.8966 12.0256 10.7339 11.9233L5.73391 8.78046C5.58834 8.68896 5.5 8.52908 5.5 8.35714V3.64286C5.5 3.47092 5.58834 3.31104 5.73391 3.21954L10.7339 0.0766817ZM6.5 4.54772V8.08086L10.5 10.5951V7.062L6.5 4.54772ZM11.5 7.062V10.5951L15.5 8.08086V4.54772L11.5 7.062ZM15.0605 3.64286L11 6.19514L6.93955 3.64286L11 1.09057L15.0605 3.64286Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.73391 8.07668C5.89657 7.97444 6.10343 7.97444 6.26609 8.07668L11.2661 11.2195C11.4117 11.311 11.5 11.4709 11.5 11.6429V16.3571C11.5 16.5291 11.4117 16.689 11.2661 16.7805L6.26609 19.9233C6.10343 20.0256 5.89657 20.0256 5.73391 19.9233L0.733914 16.7805C0.588344 16.689 0.5 16.5291 0.5 16.3571V11.6429C0.5 11.4709 0.588344 11.311 0.733914 11.2195L5.73391 8.07668ZM1.5 12.5477V16.0809L5.5 18.5951V15.062L1.5 12.5477ZM6.5 15.062V18.5951L10.5 16.0809V12.5477L6.5 15.062ZM10.0605 11.6429L6 14.1951L1.93955 11.6429L6 9.09057L10.0605 11.6429Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7339 8.07668C15.8966 7.97444 16.1034 7.97444 16.2661 8.07668L21.2661 11.2195C21.4117 11.311 21.5 11.4709 21.5 11.6429V16.3571C21.5 16.5291 21.4117 16.689 21.2661 16.7805L16.2661 19.9233C16.1034 20.0256 15.8966 20.0256 15.7339 19.9233L10.7339 16.7805C10.5883 16.689 10.5 16.5291 10.5 16.3571V11.6429C10.5 11.4709 10.5883 11.311 10.7339 11.2195L15.7339 8.07668ZM11.5 12.5477V16.0809L15.5 18.5951V15.062L11.5 12.5477ZM16.5 15.062V18.5951L20.5 16.0809V12.5477L16.5 15.062ZM20.0605 11.6429L16 14.1951L11.9395 11.6429L16 9.09057L20.0605 11.6429Z"
      />
    </SvgIcon>
  );
};
