import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/**
 * Listens to Next.js router events and returns `true` if the router is in the
 * process of loading a new page, otherwise `false`.
 */
export function useIsRouterLoading() {
  const [activeStartEvents, setActiveStartEvents] = useState(0);
  const router = useRouter();

  // Attach some listeners to router events to keep track of active routing
  // changes
  useEffect(() => {
    // Increment our counter when a route change starts
    const handleStart = () => {
      setActiveStartEvents((current) => current + 1);
    };

    // Whenever a route change finishes or errors, decrement the counter (an
    // error can happen if a user clicks a link while another change is in
    // progress, thus cancelling the original change)
    const handleComplete = () => {
      setActiveStartEvents((current) => current - 1);
    };

    const handleError = () => {
      setActiveStartEvents((current) => current - 1);
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleError);

    // When unmounting, remove subscribers and reset state
    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleError);
      setActiveStartEvents(0);
    };
  }, [router.events, setActiveStartEvents]);

  // We're loading if the number of active events is > 0
  return activeStartEvents > 0;
}
