import { useRef } from 'react';
import { clsx } from 'clsx';
import { Anchor } from '@/components';

export interface HeadingLinkProps extends React.ComponentPropsWithoutRef<'h2'> {
  level: '2' | '3' | '4' | '5' | '6';
}

/**
 * A heading element (e.g. `<h2>`) in markdown content that also renders a link
 * after the title which includes a "permalink" to the section, which is
 * displayed when the heading is hovered over or focused.
 */
export function HeadingLink({
  level,
  children,
  className,
  id,
  ...props
}: HeadingLinkProps) {
  // TODO: Anchor/scroll tracking
  const obRef = useRef<HTMLAnchorElement>(null);

  const Component = `h${level}` as const;
  return (
    <Component
      className={
        // Can be rendered with 'sr-only' by footnotes in Markdown
        className === 'sr-only'
          ? 'sr-only'
          : clsx(
              'group font-normal tracking-tight text-slate-900 dark:text-slate-100',
              {
                '2': 'mt-10 border-b border-neutral-200/70 pb-1 text-3xl contrast-more:border-neutral-400 dark:border-green-100/10 contrast-more:dark:border-neutral-400',
                '3': 'mt-8 text-2xl',
                '4': 'mt-8 text-xl',
                '5': 'mt-8 text-lg',
                '6': 'mt-8 text-base',
              }[level],
              className
            )
      }
      {...props}
    >
      {children}
      {id && (
        <Anchor
          href={`#${id}`}
          id={id}
          className={clsx(
            'opacity-0 transition-opacity focus:opacity-100 group-hover:opacity-100 ltr:ml-1 rtl:mr-1',
            'px-1 text-gray-300 after:content-["#"] dark:text-neutral-700'
          )}
          aria-label="Permalink for this section"
          ref={obRef}
        />
      )}
    </Component>
  );
}
