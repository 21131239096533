import { useState, useEffect } from 'react';

/**
 * Hook that returns true once a component has been mounted and rendered for the first time. Often used to conditionally
 * render some markup that needs to wait until it is running on the client-side or to render different content on the
 * client rather than what's pre-rendered by the Next.js server.
 */
export function useIsMounted() {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, [setIsMounted]);
  return isMounted;
}
