import { useRef, useState, useEffect, KeyboardEvent } from 'react';
import { clsx } from 'clsx';
import { useFlexSearchIndexes } from './flexsearch-indexes';
import { SearchInput } from './SearchInput';
import { SearchResultsList } from './SearchResultsList';
import { useFlexSearchResults } from './flexsearch-results';
import { useSearchResultsActiveState } from './search-result-active-state';
import { useMobileMenu } from '@/nextra-theme/MobileMenuContext';
import { useIsMounted } from '@/nextra-theme/utils';

export interface SearchProps {
  className?: string;
}

/**
 * The main search component that allows searching for documentation on the
 * site. Displays an input and a list of results when searching.
 */
export function Search({ className }: SearchProps) {
  const mobileMenu = useMobileMenu();

  const [shouldLoadIndexes, setShouldLoadIndexes] = useState(false);
  const {
    data: searchIndexes,
    isError,
    isLoading,
  } = useFlexSearchIndexes(shouldLoadIndexes);

  const [searchValue, setSearchValue] = useState('');

  const searchResults = useFlexSearchResults(searchValue, searchIndexes);
  const {
    active,
    setActive,
    setFirstResultActive,
    setNextResultActive,
    setPreviousResultActive,
    navigateToActiveResult,
  } = useSearchResultsActiveState(searchResults);

  // Handle keydown events to allow the user to use the keyboard to move through
  // the search results list
  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setNextResultActive();
        return;
      case 'ArrowUp':
        e.preventDefault();
        setPreviousResultActive();
        return;
      case 'Enter':
        // If they hit enter while focused on an anchor tag, no need to navigate
        // since the default action will be to follow the link they hit enter on
        if ('tagName' in e.target && e.target.tagName !== 'a') {
          navigateToActiveResult();
        }
        return;
    }
  };

  // Clean-up the search UI after the user hits enter/escape
  const searchInput = useRef<HTMLInputElement>(null);
  const handleKeyUp = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === 'Escape') {
      searchInput.current?.blur();
      setSearchValue('');
      setActive(undefined);
      mobileMenu.setIsOpen(false);
    }
  };

  // Add a global document event handler to listen for the keyboard shortcut
  useEffect(() => {
    const handleShortcut = (e: globalThis.KeyboardEvent) => {
      const isCommandKeyShorcut = isCommandKeyShortcut();
      const commandKeyPressed =
        (isCommandKeyShorcut && e.metaKey) ||
        (isCommandKeyShorcut === false && e.ctrlKey);

      if (commandKeyPressed && e.key === 'k') {
        e.preventDefault();
        searchInput.current?.focus();
      }
    };

    document.addEventListener('keydown', handleShortcut);
    return () => {
      document.removeEventListener('keydown', handleShortcut);
    };
  }, []);

  const isMounted = useIsMounted();

  return (
    <div
      className={clsx('relative md:w-64', className)}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
    >
      {/* Backdrop for clicking away to hide results */}
      {active !== undefined && (
        <div
          className="fixed inset-0 z-10"
          onClick={() => setActive(undefined)}
        />
      )}

      <SearchInput
        ref={searchInput}
        value={searchValue}
        onChange={(nextValue) => {
          setSearchValue(nextValue);

          // If they cleared out the input, hide the results, otherwise reset
          // the first result to active (which might not be there but will
          // show the "No results" message)
          if (nextValue === '') {
            setActive(undefined);
          } else {
            setFirstResultActive();
          }
        }}
        // Load search indexes when input is focused the first time
        onFocus={() => setShouldLoadIndexes(true)}
        keyboardShortcut={
          isMounted === false ? undefined : isCommandKeyShortcut() ? (
            <>
              <span className="text-xs">⌘</span>K
            </>
          ) : (
            'CTRL K'
          )
        }
      />

      <SearchResultsList
        errorMessage={isError ? 'Failed to load search index.' : undefined}
        loadingMessage={isLoading ? 'Loading' : undefined}
        searchValue={searchValue}
        searchResults={searchResults}
        active={active}
        // Make a search result active when the mouse is moved over it
        onSearchResultMouseMove={(pageIndex, sectionIndex) => {
          setActive({ pageIndex, sectionIndex });
        }}
      />
    </div>
  );
}

function isCommandKeyShortcut() {
  return navigator.userAgent.includes('Macintosh');
}
