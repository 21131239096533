import { createPortal } from 'react-dom';
import {
  useMemo,
  useRef,
  useCallback,
  RefCallback,
  ReactNode,
  ReactElement,
} from 'react';
import { useTheme } from 'next-themes';
import { createPopper, Options as PopperOptions } from '@popperjs/core';
import { MoonIcon, SunIcon, CheckIcon } from 'nextra/icons';
import { Listbox, Transition } from '@headlessui/react';
import { clsx } from 'clsx';
import { useIsMounted } from '@/nextra-theme/utils';

export interface ThemeSwitchProps {
  compact?: boolean;
  className?: string;
}

/**
 * Component for switching between dark/light modes.
 */
export function ThemeSwitch({ compact = false, className }: ThemeSwitchProps) {
  const { setTheme, resolvedTheme, theme = '' } = useTheme();
  const isMounted = useIsMounted();

  const [trigger, container] = usePopper({
    strategy: 'fixed',
    placement: 'top-start',
    modifiers: [
      { name: 'offset', options: { offset: [0, 10] } },
      {
        name: 'sameWidth',
        enabled: true,
        fn({ state }) {
          state.styles.popper.minWidth = `${state.rects.reference.width}px`;
        },
        phase: 'beforeWrite',
        requires: ['computeStyles'],
      },
    ],
  });

  const selected = useMemo(
    () => THEME_OPTIONS.find((opt) => opt.key === theme),
    [theme]
  );
  const IconToUse = isMounted && resolvedTheme === 'dark' ? MoonIcon : SunIcon;

  return (
    <Listbox value={selected} onChange={(option) => setTheme(option.key)}>
      {({ open: isOpen }) => (
        <Listbox.Button
          ref={trigger}
          title="Change theme"
          className={clsx(
            'h-7 rounded-md px-2 text-left text-xs font-medium text-gray-600 transition-colors dark:text-gray-400',
            isOpen
              ? 'bg-gray-200 text-gray-900 dark:bg-green-100/10 dark:text-gray-50'
              : 'hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-green-100/5 dark:hover:text-gray-50',
            className
          )}
        >
          <div className="flex items-center gap-2 capitalize">
            <IconToUse />
            <span className={compact ? 'md:hidden' : ''}>
              {isMounted ? selected?.name : 'Light'}
            </span>
          </div>
          <Portal>
            <Transition
              ref={container}
              show={isOpen}
              as={Listbox.Options}
              className="z-20 max-h-64 overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black/5 dark:bg-neutral-800 dark:ring-white/20"
              leave="transition-opacity"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              {THEME_OPTIONS.map((option) => (
                <Listbox.Option
                  key={option.key}
                  value={option}
                  className={({ active: isActive }) =>
                    clsx(
                      isActive
                        ? 'bg-green-100 text-green-800 dark:bg-green-850 dark:text-white'
                        : 'text-gray-800 dark:text-gray-100',
                      'relative cursor-pointer whitespace-nowrap py-1.5',
                      'transition-colors ltr:pl-3 ltr:pr-9 rtl:pl-9 rtl:pr-3'
                    )
                  }
                >
                  {option.name}
                  {option.key === selected?.key && (
                    <span className="absolute inset-y-0 flex items-center ltr:right-3 rtl:left-3">
                      <CheckIcon />
                    </span>
                  )}
                </Listbox.Option>
              ))}
            </Transition>
          </Portal>
        </Listbox.Button>
      )}
    </Listbox>
  );
}

/**
 * Options available in the theme listbox.
 */
const THEME_OPTIONS = [
  { key: 'light', name: 'Light' },
  { key: 'dark', name: 'Dark' },
  { key: 'system', name: 'System' },
];

function Portal(props: { children: ReactNode }): ReactElement | null {
  const isMounted = useIsMounted();
  if (!isMounted) return null;
  return createPortal(props.children, document.body);
}

/**
 * https://github.com/tailwindlabs/headlessui/issues/59
 * Example implementation to use Popper: https://popper.js.org
 */
function usePopper(
  options?: Partial<PopperOptions>
): [RefCallback<Element | null>, RefCallback<HTMLElement | null>] {
  const reference = useRef<Element | null>(null);
  const popper = useRef<HTMLElement | null>(null);

  const cleanupCallback = useRef<() => void>();

  const instantiatePopper = useCallback(() => {
    if (!reference.current || !popper.current) return;

    cleanupCallback.current?.();
    cleanupCallback.current = createPopper(
      reference.current,
      popper.current,
      options
    ).destroy;
  }, [reference, popper, cleanupCallback, options]);

  return useMemo(
    () => [
      (referenceDomNode) => {
        reference.current = referenceDomNode;
        instantiatePopper();
      },
      (popperDomNode) => {
        popper.current = popperDomNode;
        instantiatePopper();
      },
    ],
    [reference, popper, instantiatePopper]
  );
}
