import { clsx } from 'clsx';
import { Anchor, AnchorProps } from '@/components';

/**
 * The footer for all pages on the site.
 */
export function Footer() {
  return (
    <footer className="bg-black pb-[env(safe-area-inset-bottom)] print:bg-transparent">
      <hr className="dark:border-neutral-800" />
      <div
        className={clsx(
          'mx-auto flex max-w-[90rem] flex-col py-10 text-white md:flex-row md:justify-between md:py-6',
          'pl-[max(env(safe-area-inset-left),1.5rem)] pr-[max(env(safe-area-inset-right),1.5rem)]'
        )}
      >
        <div className="order-2 md:order-none">
          <hr className="my-4 md:hidden" />
          &copy;{new Date().getFullYear()}{' '}
          <FooterLink href="https://chronosphere.io">Chronosphere</FooterLink>
        </div>
        <div className="order-1 flex flex-col gap-2 md:order-none md:flex-row md:gap-8">
          <span>All Rights Reserved</span>
          <FooterLink href="https://chronosphere.io/privacy/">
            Privacy Policy
          </FooterLink>
          <FooterLink href="https://chronosphere.io/eu-applicant-policy/">
            EU Applicant Notice
          </FooterLink>
        </div>
      </div>
    </footer>
  );
}

function FooterLink({ children, className, ...props }: AnchorProps) {
  return (
    <Anchor {...props} className={clsx('hover:text-gray-200', className)}>
      {children}
    </Anchor>
  );
}
