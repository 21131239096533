import { InformationCircleIcon } from 'nextra/icons';
import { clsx } from 'clsx';

export interface AlertProps {
  severity: 'info' | 'warning' | 'success' | 'error';
  children: React.ReactNode;
  className?: string;
}

export function Alert({ children, severity, className }: AlertProps) {
  return (
    <div
      className={clsx(
        'mt-6 flex overflow-x-auto rounded-lg py-2 ltr:pr-4 rtl:pl-4',
        {
          success:
            'bg-green-100 text-green-700 dark:bg-green-800 dark:text-green-100',
          error: 'bg-red-100 text-red-900 dark:bg-red-900 dark:text-red-200',
          info: 'bg-blue-50 text-blue-900 dark:bg-blue-900 dark:text-blue-50',
          warning:
            'bg-yellow-50 text-yellow-900 dark:bg-yellow-700/30 dark:text-yellow-200',
        }[severity],
        // Change the color of links used inside an alert
        '[&_a]:text-black dark:[&_a]:text-white',
        className
      )}
    >
      <div
        className="select-none text-xl ltr:pl-3 ltr:pr-2 rtl:pl-2 rtl:pr-3"
        style={{
          fontFamily:
            '"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        }}
      >
        {severityToEmoji[severity]}
      </div>
      <div className="w-full min-w-0 leading-7">{children}</div>
    </div>
  );
}

// Which emoji to use for a given severity level of an Alert
const severityToEmoji: Record<AlertProps['severity'], React.ReactNode> = {
  success: '💡',
  error: '🚫',
  info: <InformationCircleIcon className="mt-1" />,
  warning: '⚠️',
};
