import Image from 'next/image';
import { useTheme } from 'nextra-theme-docs';
import lightThemeImage from '../../public/doc-assets/pipeline-overview-light.png';
import darkThemeImage from '../../public/doc-assets/pipeline-overview-dark.png';

export type PipelineOverviewImageProps = Omit<
  Parameters<typeof Image>[0],
  'src'
>;

/**
 * Pipeline oveview image that renders the correct image based on the theme (i.e. dark vs light mode).
 */
export function PipelineOverviewImage(props: PipelineOverviewImageProps) {
  const { resolvedTheme } = useTheme();
  let src = lightThemeImage;
  if (resolvedTheme === 'dark') {
    src = darkThemeImage;
  }

  // "alt" is a required prop for consumers
  // eslint-disable-next-line jsx-a11y/alt-text
  return <Image src={src} {...props} />;
}
