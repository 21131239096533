import escapeStringRegexp from 'escape-string-regexp';
import type { ReactNode } from 'react';
import { memo } from 'react';

export interface HighlightMatchesProps {
  displayValue: string;
  searchValue: string;
  className?: string;
}

/**
 * Component that highlights instances of the `searchValue` in the
 * `displayValue`.
 */
export const HighlightMatches = memo(function HighlightMatches({
  displayValue,
  searchValue,
  className,
}: HighlightMatchesProps) {
  if (displayValue === '') {
    return null;
  }
  const escapedSearchValue = escapeStringRegexp(searchValue.trim());

  // Replace spaces with '|' so that the regex will match/highlight any words
  // in the search value if there are multiple
  const regexp = new RegExp(escapedSearchValue.replaceAll(' ', '|'), 'ig');

  // Split the display value into an array of all its characters
  const displayValueChars = displayValue.split('');

  let result: RegExpExecArray | null;
  let prevMatchLastIndex = 0;
  const content: (string | ReactNode)[] = [];
  while (
    (result = regexp.exec(displayValue)) &&
    // case `>  ` replaced previously to `>||` + some character provoke memory leak because
    // `RegExp#exec` will always return a match
    regexp.lastIndex !== 0
  ) {
    // Consume (splice) all the "Before" characters, which are all the characters
    // from the start of the array to our match, adjusting for any characters
    // we've already consumed from previous matches
    const before = displayValueChars
      .splice(0, result.index - prevMatchLastIndex)
      .join('');

    // Matched is now all the characters from the start of the array, to the the
    // end of the match, adjusting for the "before" characters we just consumed
    const matched = displayValueChars
      .splice(0, regexp.lastIndex - result.index)
      .join('');

    content.push(
      before,
      <span key={result.index} className={className}>
        {matched}
      </span>
    );

    // Keep track of what we've consumed from previous matches
    prevMatchLastIndex = regexp.lastIndex;
  }

  return (
    <>
      {content}
      {/* Any characters leftover at the end of the string that didn't match */}
      {displayValueChars.join('')}
    </>
  );
});
