import Image from 'next/image';
import { useTheme } from 'nextra-theme-docs';
import lightThemeImage from '../../public/logo__chrono-black.svg';
import darkThemeImage from '../../public/logo__chrono-white.svg';

export type LogoImageProps = Omit<Parameters<typeof Image>[0], 'src'>;

/**
 * Main logo image for the site that renders the correct image based on the theme (i.e. dark vs light mode).
 */
export function LogoImage(props: LogoImageProps) {
  const { resolvedTheme } = useTheme();
  let src = lightThemeImage;
  if (resolvedTheme === 'dark') {
    src = darkThemeImage;
  }

  // "alt" is a required prop for consumers
  // eslint-disable-next-line jsx-a11y/alt-text
  return <Image src={src} {...props} />;
}
