import Image from 'next/image';
import { useTheme } from 'nextra-theme-docs';
import lightThemeImage from '../../public/doc-assets/platform-overview-light.png';
import darkThemeImage from '../../public/doc-assets/platform-overview-dark.png';

export type PlatformOverviewImageProps = Omit<
  Parameters<typeof Image>[0],
  'src'
>;

/**
 * Platform oveview image that renders the correct image based on the theme (i.e. dark vs light mode).
 */
export function PlatformOverviewImage(props: PlatformOverviewImageProps) {
  const { resolvedTheme } = useTheme();
  let src = lightThemeImage;
  if (resolvedTheme === 'dark') {
    src = darkThemeImage;
  }

  // "alt" is a required prop for consumers
  // eslint-disable-next-line jsx-a11y/alt-text
  return <Image src={src} {...props} />;
}
