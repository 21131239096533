import { clsx } from 'clsx';
import { useEffect, useRef } from 'react';

export interface CollapseProps {
  children?: React.ReactNode;
  className?: string;
  isOpen: boolean;
  horizontal?: boolean;
}

/**
 * Helper component that renders some `<div>` elements around its children and
 * animates expanded/collapsed state when `isOpen` changes.
 */
export function Collapse({
  children,
  className,
  isOpen,
  horizontal = false,
}: CollapseProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);
  const animationTimeoutId = useRef(0);
  const initialIsOpen = useRef(isOpen);
  const isInitialRender = useRef(true);

  useEffect(() => {
    const container = containerRef.current;
    const inner = innerRef.current;
    const animation = animationTimeoutId.current;

    if (animation) {
      clearTimeout(animation);
    }

    if (isInitialRender.current || container === null || inner === null) {
      return;
    }

    container.classList.toggle('duration-500', !isOpen);
    container.classList.toggle('duration-300', isOpen);

    if (horizontal) {
      // save initial width to avoid word wrapping when container width will be changed
      inner.style.width = `${inner.clientWidth}px`;
      container.style.width = `${inner.clientWidth}px`;
    } else {
      container.style.height = `${inner.clientHeight}px`;
    }

    if (isOpen) {
      animationTimeoutId.current = window.setTimeout(() => {
        // should be style property in kebab-case, not css class name
        container.style.removeProperty('height');
      }, 300);
    } else {
      setTimeout(() => {
        if (horizontal) {
          container.style.width = '0px';
        } else {
          container.style.height = '0px';
        }
      }, 0);
    }
  }, [horizontal, isOpen]);

  useEffect(() => {
    isInitialRender.current = false;
  }, []);

  return (
    <div
      ref={containerRef}
      className="transform-gpu overflow-hidden transition-all ease-in-out motion-reduce:transition-none"
      style={initialIsOpen.current || horizontal ? undefined : { height: 0 }}
    >
      <div
        ref={innerRef}
        className={clsx(
          'transition-opacity duration-500 ease-in-out motion-reduce:transition-none',
          isOpen ? 'opacity-100' : 'opacity-0',
          className
        )}
      >
        {children}
      </div>
    </div>
  );
}
