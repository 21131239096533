import { clsx } from 'clsx';
import NextLink from 'next/link';
import { MenuIcon } from 'nextra/icons';
import { LogoImage } from './LogoImage';
import { useIsRouterLoading } from './router-loading';
import { useNormalizedPages } from '@/nextra-theme/NormalizedPagesContext';
import { useMobileMenu } from '@/nextra-theme/MobileMenuContext';
import { Anchor } from '@/components';
import { ErrorBoundary, Search } from '@/nextra-theme/components';

/**
 * The main navigation bar shown at the top of every page.
 */
export function Navbar() {
  const isRouterLoading = useIsRouterLoading();
  const { topLevelNavbarItems: navbarItems } = useNormalizedPages();
  const mobileMenu = useMobileMenu();

  return (
    <div className="sticky top-0 z-20 w-full bg-transparent print:hidden">
      <ErrorBoundary>
        {/* Sits behind the main navbar content and blurs content that's scrolled underneath it */}
        <div
          className={clsx(
            'supports-[backdrop-filter]:bg-white/70 supports-[backdrop-filter]:backdrop-blur-md supports-[backdrop-filter]:dark:bg-dark/80',
            'pointer-events-none absolute z-[-1] h-full w-full bg-white dark:bg-dark',
            'shadow-[0_2px_4px_rgba(0,0,0,.02),0_1px_0_rgba(0,0,0,.06)] dark:shadow-[0_-1px_0_rgba(255,255,255,.1)_inset]',
            'contrast-more:shadow-[0_0_0_1px_#000] contrast-more:dark:shadow-[0_0_0_1px_#fff]'
          )}
        />

        {/* Main navbar content */}
        <nav className="mx-auto flex h-[var(--chrono-navbar-height)] max-w-[90rem] items-center justify-end gap-2 pl-[max(env(safe-area-inset-left),1.5rem)] pr-[max(env(safe-area-inset-right),1.5rem)]">
          <NextLink
            href="/"
            className="flex items-center hover:opacity-75 ltr:mr-auto rtl:ml-auto ltr:md:mr-0 rtl:md:ml-0"
          >
            <LogoImage alt="Chronosphere Logo" width={200} height={34} />
          </NextLink>

          <div className="hidden md:inline-block ltr:md:ml-16 ltr:md:mr-auto rtl:md:ml-auto rtl:md:mr-16">
            {navbarItems.map((item) => {
              if (item.display === 'hidden') return null;

              // Similar to in the Sidebar, throw if we get a "menu" item since we don't currently support it (and it
              // shouldn't be used)
              if (item.type === 'menu') {
                throw new Error(
                  'Item "${item.name}" with type "menu" is not supported'
                );
              }

              const href = item.href ?? item.route;

              return (
                <Anchor
                  key={href}
                  href={href}
                  newWindow={item.newWindow}
                  className="ml-4 mr-4 font-medium hover:text-green-500"
                >
                  {item.title}
                </Anchor>
              );
            })}
          </div>

          <Search className="mx-min-w-[200px] hidden md:inline-block" />

          <button
            type="button"
            aria-label="Menu"
            className="chrono-hamburger -mr-2 rounded p-2 active:bg-gray-400/20 md:hidden"
            onClick={() => mobileMenu.setIsOpen(!mobileMenu.isOpen)}
          >
            <MenuIcon className={clsx({ open: mobileMenu.isOpen })} />
          </button>
        </nav>

        {/* An animated loading indicator for when page transitions are happening */}
        {isRouterLoading && (
          <div
            className={clsx(
              // 2px high container at the bottom of the navbar
              'absolute bottom-0 block h-[2px] w-full overflow-hidden bg-transparent',
              // The after pseudo-element is what's animated inside the container
              // and moves left to right (see tailwind theme for animation config)
              'after:absolute after:left-0 after:top-0 after:h-full after:w-1/2 after:animate-navbar-loader after:bg-green-300 dark:after:bg-green-800'
            )}
          />
        )}
      </ErrorBoundary>
    </div>
  );
}
